import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { toBoolean } from './util';
import useDidMountEffect from './hook/useDidMountEffect';

// eslint-disable-next-line react/prop-types
const SmartsUpp = ({ apiKey = '' }) => {
  if (!toBoolean(apiKey)) return '';

  const { i18n } = useTranslation('shop');

  useEffect(() => {
    console.log('SmartsUpp show');
    if (window.smartsupp) {
      const smartuppWidget = document.getElementById('smartsupp-widget-container');
      if (smartuppWidget) {
        smartuppWidget.style.display = 'block';
      }
    }

    return () => {
      console.log('SmartsUpp hide');
      // window.smartsupp && window.smartsupp('chat:hide'); // Api feature for pricing
      // window.smartsupp = undefined;

      // document.getElementById('chat-application-iframe')?.remove();
      const smartuppWidget = document.getElementById('smartsupp-widget-container');
      if (smartuppWidget) {
        smartuppWidget.style.display = 'none';
      }
    };
  }, []);

  useDidMountEffect(() => {
    if (window.smartsupp) {
      window.smartsupp('language', i18n.language);
    }
  }, [i18n.language]);

  return (
    <HelmetProvider>
      <Helmet
        // reload={reload}
        htmlAttributes={{ lang: 'pl' }}
        script={[
          {
            type: 'text/javascript',
            innerHTML: `
              var _smartsupp = _smartsupp || {};
              _smartsupp.key = '${apiKey}';
              _smartsupp.ratingEnabled = false;
              _smartsupp.hideMobileWidget = true;
              _smartsupp.language = '${i18n.language}';
              _smartsupp.color = getComputedStyle(document.body).getPropertyValue('--primary');
              window.smartsupp||(function(d) {
                var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
                s=d.getElementsByTagName('script')[0];c=d.createElement('script');
                c.type='text/javascript';c.charset='utf-8';c.async=true;
                c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
              })(document);
            `,
          },
        ]}
      />
    </HelmetProvider>
  );
};

export default SmartsUpp;
