import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import storageLocal from '../util/storage';
import api from '../service/api.ts';
import { toBoolean } from '../util';
import { SpinnerFixed } from '../spinner';

const getOsobaStatus = async () => {
  const resp = await api.getOsobaStatus();
  return resp?.data;
};

const RouteArticleRedirect = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!toBoolean(symbol)) {
      navigate('/home');
      return;
    }

    storageLocal.set('art_redirect', symbol);

    getOsobaStatus().then((data) => {
      if (data?.status != 0) {
        navigate('/login');
        return;
      }

      navigate(`/article/show/${symbol}`);
    });
  }, []);

  return <SpinnerFixed />;
};

export default RouteArticleRedirect;
