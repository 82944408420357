import { createContext } from 'react';
import { default as storage } from '../util/storage';
import api from '../service/api';
import { hashCode, parseJSON, parseProperty, parsePropertyJSON, roundTo } from '../util/index';
import ProductMap from './productmap';
import CartMap from './cartmap';

import { createStore, createHook } from 'react-sweet-state';

enum CartType {
  Normal = 'Z',
  Pattern = 'W',
}

type ProductKey = {
  cart: number,
  symbol: string,
  jm: string,
};

type CartThirdSide = {
  name: string, 
  postalcode: string,
  city: string, 
  address: string 
  homeno: string, 
  localno: string, 
  country: string,
  email: string,
  phone: string,
};

type CartExtension = {
  uwagi: string,
  currency: string,
  symbol_fpl: string,
  // symbol_ktr: string, // not used
  symbol_odb: string,
  odbior: string,
  termin_r: string,
};

interface CartObject {
  id: number,
  name: string,
  typ: CartType,
  ext: Object,
  ts: Object,
}

interface SetProduct {
  productKey: ProductKey, 
  productPoz: number, // pozId
  selectCart: number, // selectNormal | selectPattern
  multiplier?: number, 
  calcStock?: boolean,
  calcPrice?: boolean,
  newProduct?: number,
  addQuantity?: number,
  calcSum?: boolean,
}



const cartStoreAction = {
  getWholeCart: () => async ({ getState, setState, dispatch }) => {
    getState().cart.clear();
    getState().product.clear();

    // clear
    // storage.setObject('global-cart-favorite', {}); // under code set default {}
    // storage.setObject('global-cart-measure', {}); // under code set default {}
    // storage.setObject('global-cart-select-normal', 0);
    // storage.setObject('global-cart-select-pattern', 0);

    // set
    storage.setObject('global-cart', [ ...getState().cart ]);
    storage.setObject('global-cart-product', [ ...getState().product ]);

    { // METHOD ALL IN ONE
      await dispatch(cartStoreAction.getCart(0, ''));
      await dispatch(cartStoreAction.getCartElement(0, 0));
    }

    // { // METHOD CASCADE
    //   await dispatch(cartStoreAction.getCart(0, 'Z'));
    //   await dispatch(cartStoreAction.getCart(0, 'W'));

    //   const cartZIds:Array<number> = getState().cart.getCartIds('Z');
    //   const cartWIds:Array<number> = getState().cart.getCartIds('W');

    //   for (const cartId of ([] as Array<number>).concat(cartZIds, cartWIds)) {
    //     await dispatch(cartStoreAction.getCartElement(0, cartId));
    //   };
    // }
    
    // await dispatch(cartStoreAction.getCartElement());
    await dispatch(cartStoreAction.calcSum());

    setState({ select: +(new Date) });
    console.log('%cglobal-cart-Product', "color:crimson", [ ...getState().product ]);
    console.log('CART normal selected', getState().cart.selectNormal);
  },
  getCartWithElements: (cartId: number, typ = 'Z') => async ({ dispatch }) => {
    await dispatch(cartStoreAction.getCart(cartId, typ));
    await dispatch(cartStoreAction.getCartElement(0, cartId));
  },
  getCart: (id = 0, typ = '') => async ({ getState, dispatch }) => {
    const resp = await api.getCart(id, typ);

    if (resp === undefined || (resp?.data != undefined && resp.data?.status !== 0)) {
      return ({ data: null, err: resp?.data?.info || "Błąd pobrania nagłówka koszyka"});
    }

    const dataCart = parseJSON(resp.data?.dane);

    // console.log("getCart", dataCart)

    if (!Array.isArray(dataCart?.cart) || dataCart.cart.length === 0)  {
      dataCart.cart = [];
    }

    const select = {normal: getState().cart.selectNormal, pattern: getState().cart.selectPattern};

    for (const el of dataCart.cart) {
      if (select.normal === 0 && el.typ === 'Z') {
        select.normal = el.id;
        console.log("getCart select", el.id);
        getState().cart.setSelect(el.id, 'Z');
      }

      if (select.pattern === 0 && el.typ === 'W') {
        select.pattern = el.id;
        getState().cart.setSelect(el.id, 'W');
      }

      let thirdside: CartThirdSide = {
        name: '',
        postalcode: '',
        city: '',
        address: '',
        homeno: '',
        localno: '',
        country: '',
        email: '',
        phone: '',
      };
      

      if (Array.isArray(dataCart?.third_side) && Object.keys(dataCart.third_side[0] || []).length > 0) {
        thirdside = ({ ...dataCart.third_side[0] });
      }

      let extension: CartExtension = { 
        uwagi: el.uwagi,
        currency: el.currency,
        symbol_fpl: el.symbol_fpl,
        // symbol_ktr: el.symbol_ktr, // not used
        symbol_odb: el.symbol_odb,
        odbior: ""+el.odbior,
        termin_r: el.termin_r,
      }

      // console.log("TS", thirdside); 
      // console.log("EXT", extension);

      const cart: CartObject = {
        id: el.id,
        name: el.nazwa || "[koszyk]",
        typ: el.typ,
        ext: extension, //extension
        ts: thirdside, //thirdside
      };

      getState().cart.set(el.id, cart);
    };

    // setState({ select: +(new Date) });
    await dispatch(cartStoreAction.calcSum());
    console.log('CART LIST', getState().cart.toString());

    storage.setObject('global-cart', [ ...getState().cart ]);
    storage.setObject('global-cart-select-normal', getState().cart.selectNormal);
    storage.setObject('global-cart-select-pattern', getState().cart.selectPattern);

    return ({ data: dataCart, err: null });
  },
  setCart: (name: string = '', id: number = 0, typ: CartType = CartType.Normal, cartExt: object = {}, cartTs: object = {}) => async ({ setState, getState, dispatch }) => {
    const cart: CartObject = { id, name, typ, ext: cartExt, ts: cartTs };

    if (id > 0) {
      const cartMap = getState().cart.findById(id);

      if (cartMap !== false && cart.name == '')
        cart.name = cartMap.name; 

      if (cartMap !== false && Object.keys(cartExt).length == 0)
        cart.ext = cartMap.ext; 

      if (cartMap !== false && Object.keys(cartTs).length == 0)
        cart.ts = cartMap.ts; 
    }

    const resp = await api.setCart(cart.name, id, typ, cart.ext, cart.ts);

    if (resp === undefined || (resp?.data != undefined && resp.data?.status !== 0)) {
      return false;
    }

    const cartId = parseJSON(resp.data.dane)?.nag_id;
    cart.id = cartId;

    getState().cart.set(cartId, cart);
    getState().cart.setSelect(cartId, typ);
    setState({ select: +(new Date) });
    storage.setObject('global-cart', [ ...getState().cart ]);
    storage.setObject('global-cart-select-normal', getState().cart.selectNormal);
    storage.setObject('global-cart-select-pattern', getState().cart.selectPattern);
    await dispatch(cartStoreAction.calcSum());
    console.log("setCart", id, typ);

    return cart;
  },
  setCartExtra: (id: number = 0, typ: CartType = CartType.Normal, cartExt: object = {}, cartTs: object = {}) => async ({ setState, getState, dispatch }) => {
    if (id <= 0) return false;

    const cart: CartObject = { id, name: '', typ, ext: cartExt, ts: cartTs };
    const cartMap = getState().cart.findById(id);

    if (cartMap !== false && cart.name == '')
      cart.name = cartMap.name; 

    if (cartMap !== false && Object.keys(cartExt).length == 0)
      cart.ext = cartMap.ext; 

    if (cartMap !== false && Object.keys(cartTs).length == 0)
      cart.ts = cartMap.ts; 

    const resp = await api.setCart(cart.name, id, typ, cart.ext, cart.ts);

    if (resp === undefined || (resp?.data != undefined && resp.data?.status !== 0)) {
      return false;
    }

    const cartId = parseJSON(resp.data.dane)?.nag_id;
    cart.id = cartId;

    getState().cart.set(id, cart);
    storage.setObject('global-cart', [ ...getState().cart ]);
    storage.setObject('global-cart-select-normal', getState().cart.selectNormal);
    storage.setObject('global-cart-select-pattern', getState().cart.selectPattern);
    // console.log("setCartDescription", id, typ);

    return cart;
  },
  getCartInfo: (id: number, typ: CartType = CartType.Normal) => async ({ getState }) => {
    const resp = await api.getCart(id, typ);

    if (resp === undefined || (resp?.data != undefined && resp.data?.status !== 0)) {
      return false;
    }

    const dataCart = parseJSON(resp.data?.dane);

    if (!Array.isArray(dataCart?.cart) || dataCart.cart.length === 0)  {
      return false;
    }

    const el = dataCart?.cart[0];
    if (el == undefined) {
      return false;
    }

    let thirdside: CartThirdSide = {
      name: '',
      postalcode: '',
      city: '',
      address: '',
      homeno: '',
      localno: '',
      country: '',
      email: '',
      phone: '',
    };

    if (Array.isArray(dataCart?.third_side) && Object.keys(dataCart.third_side[0] || []).length > 0)
      thirdside = ({ ... dataCart.third_side[0] });

    let extension: CartExtension = {
      uwagi: ""+el?.uwagi,
      currency: ""+el?.currency,
      symbol_fpl: ""+el?.symbol_fpl,
      //symbol_ktr: ""+cart?.symbol_ktr, // not used
      symbol_odb: ""+el?.symbol_odb,
      odbior: ""+el?.odbior,
      termin_r: ""+el?.termin_r
    };

    const cart : CartObject = {
      id: el.id, 
      name: el.name, 
      typ,
      ext: extension,
      ts: thirdside,
    };

    getState().cart.set(id, cart);
    storage.setObject('global-cart', [ ...getState().cart ]);

    return cart;
  },
  delCart: (id, typ: CartType = CartType.Normal) => async ({ setState, getState, dispatch }) => {
    const resp = await api.delCart(id, typ);

    if (resp === undefined || (resp?.data != undefined && resp.data?.status !== 0)) {
      return false;
    }

    getState().cart.del(id);
    getState().product.delCart(id);
    const cartEntry = getState().cart.findAsTyp(typ);

    if (cartEntry != undefined)
      dispatch(cartStoreAction.selectCart(cartEntry.id, typ));
    else
      setState({ select: +(new Date) });

    storage.setObject('global-cart', [ ...getState().cart ]);
    storage.setObject('global-cart-product', [ ...getState().product ]);

    return true;
  },
  selectCart: (id, typ: CartType = CartType.Normal) => async ({ getState, setState, dispatch }) => {

    getState().cart.setSelect(id, typ);
    setState({ select: +(new Date) });
    storage.setObject('global-cart-select-normal', getState().cart.selectNormal);
    storage.setObject('global-cart-select-pattern', getState().cart.selectPattern);
    console.log("selectCart", id, typ);

    await dispatch(cartStoreAction.calcSum());
  },
  getCartElement: (pozId = 0, nagId = 0) => async ({ getState, setState, dispatch }) => {
    const resp = await api.getCartElement(nagId, pozId);

    console.log('CART POZ data', resp);
    if (resp === undefined || (resp?.data != undefined && resp.data?.status !== 0)) {
      return ({ data: null, err: resp?.data?.info || "Błąd pobrania pozycji koszyka"});
    }

    const cartPoz = parseJSON(resp?.data.dane);
    if (!Array.isArray(cartPoz) || cartPoz.length <= 0) {
      console.log('CART POZ NULL');
      return ({ data: [], err: null});
    }
    
    const cart = getState().cart;
    console.log('CART POZ', nagId, pozId, cartPoz);
    // TODO: will be support JM (default 'szt')
    for (const el of cartPoz) {
      if (!cart.has(el.id_nagl))
        continue;

      const productKey:ProductKey = { 
        cart: el.id_nagl, 
        symbol: el.symbol_art, 
        jm: el.symbol_jmp,
      };
      
      const productPoz:number = +el.poz_id;

      console.log("CART POZ 2", el);

      const product = {
        hash: hashCode(el.symbol_art),
        id: parseInt(el.id, 10),
        symbol: el.symbol_art,
        input: 1,
        max: parseFloat(el.tmp_stock), 
        confirm: parseFloat(el.ilosc),
        price: parseFloat(el.cena),
        price_fixed: parseFloat(el?.cena_stala || 0),
        price_base: parseFloat(el.cena_nom || 0),
        price_feat: parseFloat(el.cena_cech || 0),
        price_discount: parseFloat(el.cena != el.cena_jm ? el.cena : 0),
        price_purchase: parseFloat(el.cena_zak_n || 0),
        tax_rate: parseFloat(el.tax_rate || 1),
        jm: el.symbol_jmp,
        convert: parseFloat(el.przelicz) || 1,
        precision: parseFloat(el.precision || 0),
        property: parsePropertyJSON(el.cechy),
        description: el.opis || "",
      };

      getState().product.set(productKey, productPoz, product);
      getState().position[JSON.stringify({ ...productKey })] = productPoz;
      // console.log('product Map', productKey, productPoz, product);
    };

    storage.setObject('global-cart-product', [ ...getState().product ]);

    // dispatch(cartStoreAction.calcSum());
    //console.log('product Map all', getState().product);

    return ({ data: cartPoz, err: null });
  },
  setProduct: ({ productKey, productPoz, selectCart, multiplier = 1, calcStock = false, calcPrice = false, calcSum = true, newProduct = -1, addQuantity = 0 } : SetProduct) => async ({ getState, setState, dispatch }) => {
    setState({ loading: true, done: false });

    const product = { ...getState().product.get(productKey, productPoz) };
    // console.log('product add', product, productKey, productPoz, selectCart, product.property);
    const productPrice = parseFloat(product.price_discount) || parseFloat(product.price);
    const productPriceFixed = parseFloat(product.price_discount) > 0 ? 0 : parseFloat(product.price_fixed);

    if (addQuantity > 0) {
      product.confirm += addQuantity;
    }

    const el = {
      nag_id: selectCart,
      poz_id: newProduct == -1 ? parseInt(product.id, 10) : newProduct,
      symbol_art: product.symbol,
      ilosc: parseFloat(product.confirm) * multiplier,
      cena: productPrice,
      cena_stala: productPriceFixed,
      cena_nom: parseFloat(product.price_base),
      cena_cech: parseFloat(product.price_feat),
      cena_zak_n: parseFloat(product.price_purchase),
      symbol_jmp: product.jm,
      tax_rate: parseFloat(product.tax_rate),
      przelicz: parseFloat(product.convert),
      tmp_stock: parseFloat(product.max),
      cechy: parseProperty(product.property),
      calculate_stock: calcStock,
      calculate_price: calcPrice,
      opis: product.description,
    };

    // console.log('SET CART ELEM PRE');
    const [err, resp] = await api.to(api.setCartElement({ ...el }));
    console.log('SET CART ELEM', resp?.data, el, err);

    if (resp === undefined || (resp?.data !== undefined && resp.data?.status !== 0)) {
      setState({ loading: false, error: { hash: product.hash, msg: err || "Wystąpił błąd" } });
      return { data: 0, err: err || "Wystąpił błąd" };
    }

    const elem = parseJSON(resp.data.dane);

    if (!Array.isArray(elem) || elem.length == 0) {
      setState({ loading: false });
      return { data: 0, err: "Pusty element" };
    }

    productKey.cart = selectCart;
    productPoz = elem[0].poz_id;
    product.id = elem[0].poz_id;
    product.hash = hashCode("" + product.symbol);

    getState().position[JSON.stringify({ ...productKey })] = productPoz;
    getState().product.set(productKey, productPoz, product);
    // getState().product.fire(productKey, productPoz); // event

    storage.setObject('global-cart-product', [ ...getState().product ]);

    // console.log('%csetProduct', "color:gold", elem, productKey, getState().product.get(productKey, productPoz));
    // console.log('%cglobal-cart-Product', "color:red", [ ...getState().product ]);
    calcSum && await dispatch(cartStoreAction.calcSum());
    setState({ loading: false, done: productKey });
    dispatch(cartStoreAction.resetDone());

    return { data: +productPoz, err: false };
  },
  delProduct: (productKey: ProductKey, productPoz:number = 0) => async ({ getState, setState, dispatch }) => {
    setState({ loading: true, done:false });
    // const product = getState().product.get(productKey, productPoz);
    console.log('DEL_PRODUCT', productKey, productPoz);

    const el = {
      nag_id: productKey.cart,
      poz_id: productPoz,
    };

    const [err, resp] = await api.to(api.delCartElement({ ...el }));
    console.log('DEL CART ELEM', resp?.data, el, err);
    setState({ loading: false });

    if (resp === undefined || (resp?.data != undefined && resp.data?.status !== 0)) {
      setState({ loading: false });
      return { data: productPoz, err: err || "Wystąpił błąd" };
    }

    // console.log('DEL ELEM', getState().product.get(productKey, productPoz));

    getState().product.del(productKey, productPoz);
    getState().position[JSON.stringify({ ...productKey })] = getState().product.getLastPosition(productKey);
    storage.setObject('global-cart-product', [ ...getState().product ]);

    // console.log("delProduct", productKey, productPoz);
    await dispatch(cartStoreAction.calcSum());

    if (getState().product.countProduct(productKey.cart) === 0) {
      console.log('selectCart 0', productKey.cart, getState().product);
      getState().cart.del(el.nag_id);
      // setState({ select: +(new Date) });
      dispatch(cartStoreAction.getCart());
    }

    setState({ loading: false, done:productKey });
    dispatch(cartStoreAction.resetDone());
    
    return { data: 0, err: false };
  },
  revertProduct: (productKey: ProductKey, productPoz:number, product) => ({ getState, setState }) => {
    // setState({ error: { ...getState().error, hash: product.hash } });
    getState().product.set(productKey, productPoz, { ...product });
    // storage.setObject('global-cart-product', [ ...getState().product ]);
  },
  setMeasure: (measure) => ({ getState, setState }) => {
    const measureState = { ...getState().measure, ...measure };
    
    setState({ measure: measureState });
    storage.setObject('global-cart-measure', getState().measure);

    console.log('SET MEASURE', getState().measure);
  },
  getFavoriteCnt: () => ({ getState }) => {
    return getState().favorite || 0;
  },
  setFavoriteCnt: (cnt, inc = false) => ({ getState, setState }) => {
    const favoriteCnt = (inc == true ? getState().favorite + cnt : cnt);
    setState({ favorite: favoriteCnt });
    storage.setObject('global-favorite-cnt', favoriteCnt);

    console.log('SET FAVORITE', getState().favorite);
  },
  getPosition:  (productKey: ProductKey) => ({ getState }) => {
    const pos = getState().position[JSON.stringify(productKey)];
    return +pos || 0;
  },
  setPosition:  (productKey: ProductKey, productPoz:number) => ({ getState }) => {
    // getState().product.consoleList();
    getState().position[JSON.stringify(productKey)] = productPoz;
  },
  getProperty: (productKey: ProductKey, productPoz:number) => ({ getState, setState, dispatch }) => {
    setState({ loading: true });

    const product = getState().product.get(productKey, productPoz);

    if (product == undefined) {
      setState({ loading:false });
      return false;
    }

    setState({ loading:false });
    return product?.property || {};
  },
  setProperty: (productKey: ProductKey, productPoz:number, property:object, propPrice:number, itemPrice:number, reload:boolean = false) => async ({ getState, setState, dispatch }) => {
    setState({ loading: true });
    getState().property[JSON.stringify(productKey)] = { ...property };

    // for autorefresh in cart
    const product = getState().product.get(productKey, productPoz);
    // console.log("%cSETPTOPREY", "color:red", product, productKey, productPoz);

    if (product == undefined) {
      // console.log("cart setProperty", "product not found", productKey)
      setState({ loading:false });
      return false;
    }

    getState().product.set(productKey, productPoz, {
      ...product,
      price_feat: +propPrice,
      price_fixed: +itemPrice,
      property: { ...product.property, ...property }
    });

    // if (productPoz == 0) {
    //   setState({ loading:false });
    //   return false;
    // }

    if (reload) {
      const result = await dispatch(cartStoreAction.setProduct({ productKey, productPoz, selectCart: getState().cart.selectNormal }));
      return result.err === false;
    }
    
    setState({ select: +(new Date) });
    setState({ loading: false });
    return true;
  },
  setDescription: (productKey: ProductKey, productPoz:number, description:string) => async ({ getState, setState, dispatch }) => {
    setState({ loading: true });
    const product = getState().product.get(productKey, productPoz);

    if (product == undefined) {
      setState({ loading:false });
      return false;
    }

    getState().product.set(productKey, productPoz, { ...product, description });
    const result = await dispatch(cartStoreAction.setProduct({ productKey, productPoz, selectCart: getState().cart.selectNormal }));
    return result.err === false;
  },
  calcSum: () => ({ getState, setState }) => {
    const { product, cart } = getState();

    // console.log('CALC SUM', cart);
    const cartIdsZ = cart.getCartIds('Z');
    const cartIdsW = cart.getCartIds('W');
    const currency = cart.getCurrency('Z');

    const productFilteredZ = product.entries().filter(el => cartIdsZ?.indexOf(parseJSON(el[0])?.cart) !== -1);
    // const productFilteredW = product.entries().filter(el => cartIdsW?.indexOf(parseJSON(el[0])?.cart) !== -1);

    const count = productFilteredZ.reduce((acc, val) => (
      val[1]?.confirm > 0 ? ++acc : acc
    ), 0);

    // const countW = +productFilteredW.length;
    const countW = +cartIdsW.length;

    const price = productFilteredZ.reduce((acc, val) => {
      const productPrice = (parseFloat(val[1].price_discount) || parseFloat(val[1].price_fixed) || parseFloat(val[1].price)) + parseFloat(val[1].price_feat);
      return val[1]?.confirm > 0 ? (val[1].confirm * productPrice * (val[1]?.convert || 1)) + acc : acc
    }, 0);

    console.log('CALC SUM', price, count, countW);
    cart.setSummary({ price, count, countW, currency });
    setState({ summary: +(new Date) });
  },
  notifySelect: () => ({ setState }) => {
    setState({ select: +(new Date()) });
  },
  resetDone: () =>  ({ setState }) => {
    setState({ done: false });
  },
  getProductState: () => ({ getState }) => {
    return getState().product;
  },
};

//storage.clear();

const CartContext = createContext(undefined);

const cartStorage: CartMap = new CartMap(storage.getObject('global-cart', []));
const cartProductStorage: ProductMap = new ProductMap(storage.getObject('global-cart-product', []));
const favoriteCntStorage = storage.getObject('global-favorite-cnt', 0);

// const cartMeasureStorage = storage.getObject('global-cart-measure', {});
// const cartPositionStorage = storage.getObject('global-cart-position', {});
// const cartPropertyStorage = storage.getObject('global-cart-property', {});

cartStorage.selectNormal = storage.getObject('global-cart-select-normal', 0);
cartStorage.selectPattern = storage.getObject('global-cart-select-pattern', 0);

console.log('INIT', cartStorage.toString(), cartProductStorage.toString()); //, cartFavoriteStorage, cartMeasureStorage);

const CartStore = createStore({
  initialState: {
    cart: cartStorage,
    product: cartProductStorage,
    position: {}, // cartPositionStorage,
    property: {}, // cartPropertyStorage,
    measure: {}, // cartMeasureStorage,
    favorite: favoriteCntStorage,
    select: 0, // timestamp changed
    summary: 0,
    loading: false,
    done: false,
    error: { hash: 0, msg: "" },
  },
  actions: cartStoreAction,
  name: 'cart',
});

export const useCart = createHook(CartStore);
// export const CartSubscriber = createSubscriber(CartStore);
export default CartContext;
